import api from '../index'

const login = {
    // 绑定信息登录
    login(data) {
        return api({
            url: 'v2/ai/login',
            method: 'post',
            data: data
        })
    }
}
export default login
