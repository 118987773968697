<!-- 主页 -->
<template>
  <div class="page-main"  id="message">
    <div v-if="loading" class="login-loading">
      <van-loading type="spinner" vertical>
        加载中...
      </van-loading>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import { setSession } from "../../utils/sessionUtils"
import CommonData from "../../utils/commonData"
import login from "@/api/user/login"
import { Toast } from 'vant';

export default {
  name: "index",
  components: {
  },
  setup() {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();
    const wxLogin = () => {
      login.login({}).then(res=> {
        console.log(res)
        setSession(CommonData.KeyStorages.KEY_USER_TOKEN, res.data.token)
        data.isLogin = true
        setSession(CommonData.KeyStorages.KEY_IS_LOGIN, true)
        if (res.data.user_id != null) {
          // 已绑定
          goHome()
        } else {
          // 需要绑定
          goBind()
        }
      }).catch(err=>{
        console.log(err)
      })
    }

    onMounted(() => {
      console.log('index=====>')
      //获取传入参数
      const options = $router.currentRoute.value.query;
      console.log(options)
      if (options!=null) {
        if (options.open_id != null) {
          var open_id = options.open_id
          console.log('set session=====>', open_id)
          setSession(CommonData.KeyStorages.KEY_USER_OPEN_ID, open_id)
          wxLogin()
        }
      }
    });
    const goBind = () => {
      $router.push({
        name: 'bind'
      })
    }
    const goHome = () => {
      if (data.isLogin) {
        // 进入角色信息页面
        $router.push({
          name: 'home'
        })
      }
    }

    const data = reactive({
      loading: true
    });

    return {
      ...toRefs(data),
      goBind,
      goHome
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.page-main {
  width: 100%;
  height: 100%;
  background: #EEE;
  .login-loading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

</style>
